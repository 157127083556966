@import '~normalize-scss/fork-versions/default/normalize';
@import "~@progress/kendo-theme-default/dist/all.scss";

@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';
@import 'abstracts/helpers';
@import 'base/reset';
@import 'base/typography';
@import 'base/components';
@import 'custom-kendo/custom-kendo';

app-root > *:nth-child(2) {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
}

.header {
    flex-shrink: 0;
    position: relative;
    z-index: 3;
}

.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding: 24px;
}

.content-holder {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0 16px 22px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: #fff;
}

.study-title-wrap {
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    padding: 24px 0 26px 8px;
}