// Typography
@font-face {
    font-family: 'icomoon';
    src: url('/assets/icon-fonts/icon-fonts.eot?wvk232');
    src: url('/assets/icon-fonts/icon-fonts.eot?wvk232#iefix') format('embedded-opentype'),
        url('/assets/icon-fonts/icon-fonts.ttf?wvk232') format('truetype'),
        url('/assets/icon-fonts/icon-fonts.woff?wvk232') format('woff'),
        url('/assets/icon-fonts/icon-fonts.svg?wvk232#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}

.icon-question:before {
    content: "\e912";
}

.icon-account:before {
    content: "\e911";
}

.icon-alert:before {
    content: "\e901";
}

.icon-arrow-down:before {
    content: "\e902";
}

.icon-checked:before {
    content: "\e903";
}

.icon-columns:before {
    content: "\e904";
}

.icon-download-xml:before {
    content: "\e906";
}

.icon-eye:before {
    content: "\e90f";
}

.icon-filter:before {
    content: "\e907";
}

.icon-loop:before {
    content: "\e90e";
}

.icon-message:before {
    content: "\e90b";
}

.icon-play:before {
    content: "\e910";
}

.icon-rate-review:before {
    content: "\e90c";
}

.icon-repeat:before {
    content: "\e90d";
}

.icon-warning:before {
    content: "\e90a";
}

.icon-notification:before {
    content: "\e900";
}

.icon-external-link:before {
    content: "\e905";
}

.icon-logout:before {
    content: "\e908";
}

.icon-policy:before {
    content: "\e909";
}

.icon-close:before {
    content: "\e91b";
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
.h {
    font-family: $headings-font-family;
    font-weight: bold;
    margin: 0 0 0.5em;
    color: $headings-color;
}

h1, .h1 {
    margin: 0 0 30px;
    font: #{$h1-font-size}/#{38px} $base-font-serif;
    text-transform: uppercase;
}

h2, .h2 {
    margin: 0 0 20px;
    font: #{$h2-font-size}/#{32px} $base-font-serif;
}

h3, .h3 {
    font-size: $h3-font-size;
}

h4, .h4 {
    font-size: $h4-font-size;
}

h5, .h5 {
    font-size: $h5-font-size;
}

h6, .h6 {
    font-size: $h6-font-size;
}

p {
    margin: 0 0 1em;
}

a {
    color: $base-link-color;
    text-decoration:none;
    &:hover{
        color: $base-link-hover-color;
    }
}