html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: border-box;
}

* {
    max-height: 1000000px;
}

body {
    color: $base-text-color;
    background: $base-background-color;
    font: #{$base-font-size}/#{$base-line-height} $base-font-sans-serif;
    min-width: $base-min-width;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// adaptive images
img {
    max-width: 100%;
    height: auto;
    vertical-align:top;
}

a, button, input[type="submit"] {
    cursor:pointer;
    transition: color $animation-speed $animation-effect, background-color $animation-speed $animation-effect, opacity $animation-speed $animation-effect, border-color $animation-speed $animation-effect, visibility $animation-speed $animation-effect, transform $animation-speed $animation-effect;
    -webkit-transition: color $animation-speed $animation-effect, background-color $animation-speed $animation-effect, opacity $animation-speed $animation-effect, border-color $animation-speed $animation-effect, visibility $animation-speed $animation-effect, -webkit-transform $animation-speed $animation-effect;
}

hr {
    width: 100%;
    height: 1px;
    margin: 0 0 12px;
    border: 0;
    background: $alt-border-color;
}