// list reset
%listreset {
  margin: 0;
  padding: 0;
  list-style: none;
}

// clearfix
%clearfix {
 &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.clearfix {
  @extend %clearfix;
}

// transition
%transition {
  transition: all $animation-speed $animation-effect;
}

// justify nav
%justify {
  text-align: justify;
  > * {
    display: inline-block;
    vertical-align: top;
    text-align: left;
  }
  &:after {
    content: '';
    width: 100%;
    display: inline-block;
    vertical-align: top;
  }
}



/// * Helper class to truncate and add ellipsis to a string too long for it to fit
/// * on a single line.
/// * 1. Prevent content from wrapping, forcing it on a single line.
/// * 2. Add ellipsis at the end of the line.

.ellipsis {
  white-space: nowrap; /* 1 */
  text-overflow: ellipsis; /* 2 */
  overflow: hidden;
}
