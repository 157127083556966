.k-pager-numbers .k-link.k-state-selected,
.k-list .k-item.k-state-selected,
.k-list-optionlabel.k-state-selected {
    color: $white;
    box-shadow: none;
    background: $btn-background-color-primary;
}

/* Do not show extended filtration in kendo-grid filter*/
kendo-grid-string-filter-menu,
kendo-grid-date-filter-menu {
    .k-dropdown {
        display: none !important;
    }
}

kendo-grid-string-filter-menu-input {
    &:last-child {
        display: none;
    }
}

.k-calendar .k-calendar-navigation li:hover,
.k-calendar .k-calendar-navigation li.k-state-hover,
.k-calendar .k-calendar-view .k-today {
    color: $base-link-color;
}

.k-button-group .k-button:focus,
.k-button-group .k-button.k-state-focus,
.k-button-group .k-button.k-state-focused,
.k-button-group .k-button-outline:focus,
.k-button-group .k-button.k-outline:focus,
.k-button-group .k-button-outline.k-state-focus,
.k-button-group .k-state-focus.k-button.k-outline,
.k-button-group .k-button-outline.k-state-focused,
.k-button-group .k-state-focused.k-button.k-outline {
    box-shadow: none;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
    border-color: $active-border-color;
    background-color: $active-border-color;
}

.k-checkbox:checked:focus,
.k-checkbox.k-checked.k-state-focus {
    box-shadow: 0 0 0 2px rgba($active-border-color, 0.3);
}

.k-button:active,
.k-button.k-state-active,
.k-button-secondary:active,
.k-button-secondary.k-state-active {
    background: #fff;

    &:hover {
        background: none;
        border-color: $base-primary-color;
    }
}

.k-button-primary:active,
.k-button.k-primary:active,
.k-button-primary.k-state-active,
.k-state-active.k-button.k-primary {
    background: $btn-background-color-success;

    &:hover {
        background: $btn-background-color-success-hover;
    }

    &:disabled,
    &.disabled {
        background: $btn-background-color-default;
    }
}

// kendo grid customization styles
.k-grouping-header {
    padding: 10px 12px 7px;
    color: $soft-text-color;
    border-color: $smooth-border-color;
    background: none;

    .k-group-indicator {
        display: inline-block;
        vertical-align: top;
        position: relative;
        height: 32px;
        padding: 0;
        font-size: 14px;
        line-height: 22px;
        color: $base-text-color;
        text-align: left;
        border: 1px solid $btn-border-color;
        border-radius: 4px;
        background: $btn-background-color;

        .k-icon {
            position: absolute;
            top: 6px;
            width: 20px;
            margin: 0;
            font-size: 20px;
            line-height: 1;
            color: $soft-text-color;
            text-align: center;
        }

        .k-link {
            min-width: 94px;
            padding: 4px 28px;

            .k-icon {
                left: 4px;
            }
        }

        .k-button.k-bare {
            position: absolute;
            right: 0;
            top: 0;
            width: 24px;
            height: 100%;
            min-width: 0;
            opacity: 1;

            .k-icon {
                right: 4px;
                font-size: 18px;
            }
        }

        .k-link,
        .k-button {
            &:hover {
                .k-icon {
                    color: $base-link-color;
                }
            }
        }
    }
}

.k-grid {
    color: $alt-text-color;
    border-width: 1px 0 0;
    border-color: $smooth-border-color;

    &.is-grouped {
        .k-grid-header .k-header:not(.k-group-cell) {
            width: auto;
        }
    }

    tr {
        &, &:hover {
            background: none;
        }

        &.k-alt {
            background: $alt-row-background-color;
        }

        &:last-child {
            td {
                border-bottom: 1px solid $smooth-border-color;
            }
        }
    }

    .k-grouping-row {
        &:first-child {
            ~ .k-grouping-row {
                td:not(.k-group-cell) {
                    border-top: 1px solid $smooth-border-color;
                }
            }
        }

        + tr:not(.k-grouping-row) {
            td:not(.k-group-cell) {
                border-top: 1px solid $smooth-border-color;
            }
        }

        .k-icon {
            color: inherit;
        }
    }

    th,
    td {
        overflow: visible;
        text-overflow: unset;
        border: 0;
        word-break: break-all;
        word-break: break-word;
        word-wrap: break-word;
        border-left: 1px solid $smooth-border-color;
    }

    th {
        height: 56px;
        white-space: normal;
    }

    td {
        &:first-child {
            border-left-width: 1px;
        }
    }
}

.k-grid-header {
    .k-header {
        vertical-align: middle;
        color: $alt-text-color;

        &:first-child {
            border-left-width: 1px;
        }

        > kendo-grid-filter-menu {
            .k-grid-filter {
                bottom: auto;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .k-icon {
        width: 20px;
        height: 20px;
        font-size: 20px;
        line-height: 1;
        text-align: center;

        &:before {
            display: block;
        }
    }

    .k-grid-filter {
        width: 20px;
        height: 20px;
        padding: 0;
        color: $notification-text-color;
        box-shadow: none !important;
        background: none !important;

        &:hover {
            color: darken($notification-text-color, 8%);
        }

        &.k-state-active {
            color: $base-link-color;
            background: none;

            &:hover {
                color: darken($base-link-color, 8%);
            }
        }
    }

    .k-i-filter {
        &:before {
            @include add-icon("\e907");
        }
    }

    .k-i-sort-asc-sm,
    .k-i-sort-desc-sm {
        flex-shrink: 0;
        color: $base-link-color;

        &:hover {
            color: $base-link-hover-color;
        }
    }
}

.k-i-sort-asc-sm,
.k-i-sort-desc-sm {
    cursor: pointer;

    &:before {
        @include add-icon("\e902");
    }
}

.k-i-sort-asc-sm {
    &:before {
        transform: rotate(180deg);
    }
}

.k-grouping-row td,
tbody .k-group-cell {
    color: $alt-text-color;
    background: $white;
}

.k-grid-content {
    overflow-x: hidden;
}

.k-column-title {
    flex-grow: 1;
    text-overflow: unset;
    word-break:break-all;
    word-break:break-word;
    word-wrap:break-word;
    white-space: pre-wrap;
}

.k-grid-aria-root {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: auto !important;
    overflow-y: hidden !important;

    .k-grid-header,
    .k-grid-container {
        min-width: 1700px;
    }

    .k-grid-header {
        flex-shrink: 0;
        color: $base-text-color;
        background: $alt-background-color;
    }

    th.k-header,
    .k-column-title {
        font-weight: bold;
    }
}

.k-pager-wrap {
    flex-shrink: 0;
    padding-top: 30px;
    background: none;
}

.k-cell-inner {
    > .k-link {
        padding: 18px 12px 17px;
    }
}

.k-pager-numbers {
    font: bold #{16px}/#{20px} $base-font-serif;

    .k-link {
        min-width: 28px;
        height: 28px;
        box-shadow: none;
    }
}

.k-filter-menu {
    .k-action-buttons {
        padding: 16px 16px 11px;
        margin: 8px 0 0;
    }

    .k-textbox,
    .k-widget {
        margin: 11px 16px 0;
        width: calc( 100% - ( 2 * 16px ));
    }
}

.k-button-group {
    justify-content: space-between;
    border-top: 1px solid $smooth-border-color;

    .k-button {
        border-radius: 4px;

        &:first-child,
        &:last-child {
            border-radius: 4px;
        }
    }
}

.k-animation-container-shown {
    box-shadow: none;

    .k-grid-filter-popup {
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    }
}

.k-grid-filter-popup {
    width: 230px;
    border-radius: 4px;
    border: 1px solid $base-border-color;
}

.k-button {
    font-weight: 500;
    color: $base-text-color;
    padding: 6px 8px;
    min-width: 91px;
    border: 1px solid $base-border-color;
    box-shadow: none;
    background: none;

    &:hover {
        background: none;
        border-color: $base-primary-color;
    }

    &.k-primary {
        color: $btn-text-color-success;
        border: none;
        background: $btn-background-color-success;

        &:hover {
            background: $btn-background-color-success-hover;
        }

        &:disabled,
        &.disabled {
            background: $btn-background-color-default;
        }
    }
}

.k-textbox {
    border: 1px solid $base-border-color;
    border-radius: 4px;
    height: 38px;
}

.k-pager-numbers {
    .k-link {
        color: $base-primary-color;

        &.k-state-selected {
            &:hover {
                background-color: $base-primary-color;
                color: $btn-text-color-primary;
            }
        }

        &:hover {
            background-color: $alt-background-color;
            color: $base-primary-color;
        }
    }
}

.k-datepicker {
    .k-picker-wrap {
        border-color: $base-border-color;
        border-radius: 4px;
    }

    .k-select {
        background: $btn-background-color-default;
    }
}

.k-input {
    height: 36px;
    text-transform: uppercase;
    padding: 4px 12px;
}

.k-calendar .k-calendar-navigation li:hover,
.k-calendar .k-calendar-navigation li.k-state-hover,
.k-calendar .k-calendar-view .k-today {
    color: $base-link-color;
}

.k-button-group .k-button:focus,
.k-button-group .k-button.k-state-focus,
.k-button-group .k-button.k-state-focused,
.k-button-group .k-button-outline:focus,
.k-button-group .k-button.k-outline:focus,
.k-button-group .k-button-outline.k-state-focus,
.k-button-group .k-state-focus.k-button.k-outline,
.k-button-group .k-button-outline.k-state-focused,
.k-button-group .k-state-focused.k-button.k-outline {
    box-shadow: none;
}

.k-checkbox {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
    border-color: $active-border-color;
    background-color: $active-border-color;
}

.k-checkbox:checked:focus,
.k-checkbox.k-checked.k-state-focus {
    box-shadow: 0 0 0 2px rgba($active-border-color, 0.3);
}

.k-button:active,
.k-button.k-state-active,
.k-button-secondary:active,
.k-button-secondary.k-state-active {
    background: #fff;

    &:hover {
        background: none;
        border-color: $base-primary-color;
    }
}

.k-button-primary:active,
.k-button.k-primary:active,
.k-button-primary.k-state-active,
.k-state-active.k-button.k-primary {
    background: $btn-background-color-success;

    &:hover {
        background: $btn-background-color-success-hover;
    }

    &:disabled,
    &.disabled {
        background: $btn-background-color-default;
    }
}

.k-pager-wrap:focus,
.k-pager-wrap.k-focus,
.k-pager-wrap.k-state-focus,
.k-pager-wrap.k-state-focused {
    box-shadow: none;
}