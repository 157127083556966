$black: #000;
$white: #fff;

$gray-100: #232425; // plain text color
$gray-200: #45484a; // titles color
$gray-400: #8a9195; // notifications color
$gray-600: #bdc4c8; // borders color
$gray-700: #ccc;
$gray-800: #dee1e3; // default button background color, separators
$gray-900: #f6f7f7; // even grid row color
$gray-light-smooth: #e5e5e5;

$blue: #006cb6;
$blue-alt: #99c4e2;
$blue-alt-accent: #cfe2f1;
$blue-light: #e5f0f8;
$blue-lighter: #f3f7f9;
$blue-dark: #0a416e;
$red: #eb5757;
$red-light: #fddddb;
$green: #39b54a;
$green-dark: #2e913b;
$green-accent: #2e913b;

$base-font-sans-serif: 'Roboto', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$base-font-serif: 'Roboto Slab', 'Times New Roman', 'Times', 'Baskerville', 'Georgia', serif;

$icons-folder-url: "/assets/images/";

// Body
$base-primary-color: $blue !default;
$base-success-color: $green !default;
$alt-base-success-color: $green-dark !default;
$base-error-color: $red !default;
$alt-error-color: $red-light !default;
$base-text-color: $gray-100 !default;
$alt-text-color: $gray-200 !default;
$soft-text-color: $gray-400 !default;
$notification-text-color: $gray-400 !default;
$notification-text-color-secondary: $gray-600 !default;
$action-text-color: $blue-dark !default;
$base-border-color: $gray-600 !default;
$alt-border-color: $gray-light-smooth !default;
$smooth-border-color: $gray-800 !default;
$link-border-color: $blue-alt !default;
$active-border-color: $base-primary-color !default;
$base-background-color: $blue-lighter !default;
$alt-background-color: $blue-light !default;
$alt-row-background-color: $gray-900 !default;
$light-background-color: $white !default;
$dialog-backgdround-color: $gray-700 !default;
$base-font-size: 14px;
$base-line-height: 20px;
$base-font-family: $base-font-sans-serif !default;

$base-min-width: 320px;

// Links
$base-link-color: $base-primary-color;
$base-link-hover-color: lighten($base-primary-color, 12%);

// Buttons 
$btn-padding: 5px 12px !default;
$btn-text-color: $alt-text-color !default;
$btn-border-color: $base-border-color !default;
$btn-background-color: $white !default;
$btn-text-color-success: $white !default;
$btn-background-color-success-hover: $green-accent !default;
$btn-background-color-success: $base-success-color !default;
$btn-text-color-primary: $white !default;
$btn-background-color-primary: $base-link-color !default;
$btn-text-color-primary-light: $base-link-color !default;
$btn-text-color-primary-light-hover: $blue-alt-accent !default;
$btn-background-color-primary-light: $alt-background-color !default;
$btn-text-color-default: $alt-text-color !default;
$btn-background-color-default: $gray-800 !default;
$btn-tertiary-background-color: $blue-alt-accent !default;

// Forms
$form-element-padding: $btn-padding !default;
$form-element-border-color: $gray-400 !default;
$form-element-focus-border-color: $black !default;
$placeholder-color: $gray-400 !default;

// Headers
$h1-font-size: 32px !default;
$h2-font-size: 24px !default;
$h3-font-size: 27px !default;
$h4-font-size: 25px !default;
$h5-font-size: 22px !default;
$h6-font-size: 19px !default;

$headings-font-family: inherit !default;
$headings-color: inherit !default;

$animation-speed: 0.25s;
$animation-effect: ease;

$breakpoints: (
    'tablet': 768px,
    'desktop': 992px,
    'desktop-large': 1220px,
);
