// Button styles
.btn {
    display: inline-block;
    vertical-align: top;
    height: 32px;
    padding: $btn-padding;
    font: 500 #{14px}/#{22px} $base-font-sans-serif;
    color: $btn-text-color;
    text-align: center;
    outline: none;
    border: 1px solid $btn-border-color;
    border-radius: 4px;
    background: $btn-background-color;

    &:hover {
        border-color: $base-primary-color;
    }

    &:disabled,
    &.disabled {
        color: $notification-text-color-secondary;
        pointer-events: none;
    }

    &.btn-primary {
        color: $btn-text-color-primary;
        border-color: transparent;
        background: $btn-background-color-primary;

        &:hover {
            background: darken($btn-background-color-primary, 8%);
        }

        &:disabled,
        &.disabled {
            background: $btn-background-color-default;
        }
    }

    &.btn-primary-light {
        color: $btn-text-color-primary-light;
        border-color: transparent;
        background: $btn-background-color-primary-light;

        &:hover {
            background: $btn-text-color-primary-light-hover;
        }

        &:disabled,
        &.disabled {
            background: $btn-background-color-default;
        }
    }

    &.btn-success {
        color: $btn-text-color-success;
        border-color: transparent;
        background: $btn-background-color-success;

        &:hover {
            background: $btn-background-color-success-hover;
        }

        &:disabled,
        &.disabled {
            background: $btn-background-color-default;
        }
    }

    &.btn-icon {
        width: 32px;
        padding: 3px 0;
        font-size: 22px;
        line-height: 1;
    }

    // Button icon + text
    &.btn-combo {
        display: inline-flex;
        vertical-align: top;
        align-items: center;
        justify-content: center;
    
        .icon {
            font-size: 24px;
            line-height: 1;
            margin: 0 6px 0 0;
        }
    }
}

// System notification styles
.system-notification {
    position: relative;
    width: 100%;
    margin: 0 0 24px;
    padding: 10px 10px 10px 48px;
    background: $alt-row-background-color;

    &.state-error {
        background: $alt-error-color;

        &:before {
            @include add-icon("\e90a");
            position: absolute;
            left: 12px;
            top: 8px;
            font-size: 24px;
            color: $base-error-color;
        }
    }
}

.notification-text {
    color: $notification-text-color;

    time {
        text-transform: uppercase;
    }
}

// Filters dropdown styles
.filters-dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 100%;
    min-width: 206px;
    max-height: calc(#{100vh} - 236px);
    overflow: hidden;
    margin: 8px 0;
    font-size: 14px;
    line-height: 18px;
    border: 1px solid $base-border-color;
    border-radius: 4px;
    background: $white;
}

// Choice element styles
.fake-choice {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 16px;
    height: 16px;
    margin-right: 4px;
    font-size: 0;
    line-height: 0;

    &.choice-large {
        width: 20px;
        height: 20px;

        input {
            &[type="radio"] {
                + span {
                    &:after {
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }

        span {
            font-size: 16px;
            line-height: 1;
        }
    }

    input {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        opacity: 0;
        cursor: pointer;

        &[type="radio"] {
            + span {
                border-radius: 50%;

                &:after {
                    content: "";
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: currentColor;
                }
            }
        }

        &:checked {
            + span {
                background-color: $active-border-color;

                &:after {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: 12px;
        line-height: 1;
        border: 1px solid $active-border-color;
        border-radius: 4px;
        background: $white;

        &:after {
            @include add-icon("\e903");
            display: block;
            color: $white;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transition: all $animation-speed $animation-effect;
        }
    }
}

// Badge text styles
.badge-text {
    display: inline-block;
    vertical-align: top;
    padding: 0 7px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    color: $alt-text-color;
    text-transform: uppercase;
    border: 1px solid transparent;
    border-radius: 4px;
    background: $btn-background-color-default;

    &.badge-primary {
        color: $btn-text-color-primary-light;
        border-color: #99c4e2;
        background: $btn-background-color-primary-light;
    }

    &.badge-success {
        color: $base-success-color;
        border-color: #b8d8b6;
        background: #edf5ed;
    }

    &.badge-warning {
        color: #8f6900;
        border-color: #ebd599;
        background: #ffedcc;
    }

    &.badge-info {
        color: #255a5c;
        border-color: #aecdce;
        background: #ebf2f3;
    }

    &.badge-alt {
        position: relative;
        top: 1px;
        padding: 0 3px;
        font-size: 10px;
        line-height: 14px;
        font-weight: bold;
        color: $white;
        text-transform: uppercase;
        background: $base-error-color;
    }
}

// Dialog styles: start
.cdk-overlay-container,
.cdk-global-overlay-wrapper {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
}

.cdk-overlay-container {
    position: fixed;
    z-index: 5;
}

.cdk-overlay-backdrop {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    transition: opacity 0.4s cubic-bezier(.25,.8,.25,1);
    opacity: 0;

    &.cdk-overlay-backdrop-showing {
        opacity: 1;
    }

    &.cdk-overlay-dark-backdrop {
        background: rgba($black, 0.4);
    }

    &.cdk-overlay-light-backdrop {
        background: rgba($white, 0.4);
    }
}

.cdk-global-overlay-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.cdk-overlay-pane {
    display: flex;
    max-width: 100%;
    max-height: 100%;
    pointer-events: auto;
}

.cdk-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.mat-dialog-container {
    width: 100%;
    height: 100%;
    min-height: inherit;
    max-height: inherit;
    overflow: auto;
    padding: 24px;
    font-size: 14px;
    line-height: 24px;
    outline: 0;
    border-radius: 4px;
    box-shadow: 0 0px 0 rgba(0, 0, 0, 0.2);
    background: $white;
}
// Dialog styles: end

// Centered window page template styles
.actions-container {
    background-color: $dialog-backgdround-color;
    flex-grow: 1;
    overflow: auto;
    padding: 20px 10px;
    font-size: 0;
    line-height: 0;
    text-align: center;
    white-space: nowrap;

    &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 1px;
        height: 100%;
    }
}

.actions-holder {
    display: inline-block;
    vertical-align: middle;
    width: 602px;
    max-width: 100%;
    padding: 24px;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    white-space: normal;
    border-radius: 4px;
    box-shadow: 0 0px 0 rgba(0, 0, 0, 0.2);
    background: $white;

    .dialog-header {
        position: relative;
        margin: 0 0 16px;
        padding: 0 24px 12px 0;
        border-bottom: 1px solid $alt-border-color;
    }

    .dialog-title {
        display: block;
        margin: 0;
        font: #{16px}/#{24px} $base-font-family;
        color: $black;
    }

    .dialog-content {
        margin: 0 -24px;
        padding: 0 24px;

        p {
            margin: 0 0 12px;
        }
    }
}